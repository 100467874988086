<template>
  <div class="py-md-8 py-6">
    <s-loading :padding="5" v-if="fetching" />
    <v-form v-else v-model="formIsValid" ref="kitchenLayoutForm" @submit.prevent="handleFormSubmit" lazy-validation>
      <v-row class="mb-md-8 mb-6">
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 mb-3 d-inline-block">
            {{ $t("createOrder.kitchenLayout.form.kitchenLayoutType.label") }}
          </label>
          <v-radio-group class="kitchen-layout-types" hide-details="auto" :rules="rules.kitchenLayoutTypeId" row
                         v-model="form.kitchenLayoutTypeId">
            <template v-for="kitchenLayoutType in kitchenLayoutTypes">
              <v-radio active-class="active" class="image-radio" :key="kitchenLayoutType.id"
                       :value="kitchenLayoutType.id">
                <template v-slot:label>
                  <div class="my-2 d-flex flex-column align-center">
                    <img :src="getAttachmentPreviewURL(kitchenLayoutType.image)" :alt="kitchenLayoutType.displayName" />
                    <span class="d-inline-block mt-1 text-body-2">{{ kitchenLayoutType.displayName }}</span>
                  </div>
                </template>
              </v-radio>
            </template>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="mt-md-8 mt-6 d-flex">
        <v-btn
          type="submit"
          :disabled="!formIsFilled || !formIsValid"
          elevation="0"
          color="primary"
          class="rounded-0 sm-full-width"
          large
        >
          {{ $t("defaults.continue") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getAttachmentPreviewURL } from "@/utils/attachment";

export default {
  data() {
    return {
      formIsValid: false,
      fetching: false,
      isCalculatorVisible: false,
      form: {
        kitchenLayoutTypeId: null
      },
      rules: {
        kitchenLayoutTypeId: [
          v => !!v || this.$t("createOrder.kitchenLayout.form.kitchenLayoutType.rules.required")
        ]
      }
    };
  },
  computed: {
    ...mapState("KitchenLayout", {
      kitchenLayoutTypes: state => state.kitchenLayoutTypes
    }),
    formIsFilled() {
      return this.form.kitchenLayoutTypeId;
    }
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Steps", ["completeStep"]),
    ...mapActions("KitchenLayout", ["getKitchenLayoutTypes", "saveSelectedKitchenLayoutType"]),
    handleFormSubmit() {
      const isValid = this.$refs.kitchenLayoutForm.validate();
      if (isValid) {
        this.saveSelectedKitchenLayoutType(this.form.kitchenLayoutTypeId);
        this.completeStep("kitchenLayout/kitchenShape");
        this.$router.push("/design-countertop/kitchen-layout/sqft");
      }
    },
    async fetchKitchenLayoutTypes() {
      if (this.kitchenLayoutTypes.length === 0) {
        this.fetching = true;
        await this.getKitchenLayoutTypes();
        this.fetching = false;
      }
    }
  },
  mounted() {
    this.fetchKitchenLayoutTypes();
    const { selectedKitchenLayoutType } = this.$store.state.KitchenLayout;
    if (selectedKitchenLayoutType) {
      this.form.kitchenLayoutTypeId = selectedKitchenLayoutType.id;
    }
  }
};
</script>
